import React, { Component } from 'react'
import Particle from '../Particle/particle'
import Fade from 'react-reveal/Fade'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import Granim from 'granim'
import { FaPlayCircle } from 'react-icons/fa'
import ModalVideo from '../ModalVideo/index.js'

class HeroBanner extends Component {
  constructor () {
    super()
    this.state = {
      isModalOpen: false,
    }
    this.openModal = this.openModal.bind(this)
  }

  openModal () {
    this.setState({ isModalOpen: true })
  }

  // gradient background
  componentDidMount () {
    if (!this.props.cover) {
      var gradient = this.props.hero_settings.css
      gradient = gradient.split('~').map(s => s.split(','))

      // eslint-disable-next-line no-unused-vars
      var granimInstance = new Granim({
        element: '#canvas-basic',
        direction: 'top-bottom',
        states: {
          'default-state': {
            gradients: gradient,
          },
        },
      })
    }
  }

  render () {
    const {
      hero_settings,
      title,
      subtitle,
      cta_title,
      cta_url,
      cta_video_link,
      cover,
    } = this.props

    var re = /~|,/
    var gradient = hero_settings.css
    gradient = gradient.split(re)

    if (cta_video_link) {
      var modal_video_link = cta_video_link.split(re)
    }

    if (!hero_settings) {
      // eslint-disable-next-line no-const-assign
      hero_settings = {
        size: 'is-medium',
        particle: true,
        font_color: '#fff',
      }
    }

    let divStyle = {}

    if (cover === 'null' || cover === undefined) {

    } else {
      divStyle = {
        backgroundImage: 'url(' + cover + ')',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }
    }

    // console.log(divStyle)

    var title_style = {}
    if (hero_settings.title_align) {
      title_style = {
        color: hero_settings.font_color,
        textAlign: hero_settings.title_align,
      }
    } else {
      title_style = {
        color: hero_settings.font_color,
      }
    }

    // console.log(modal_video_link[0])
    return (
      <div>
        <section
          className={`hero ${hero_settings.size}`}
          style={divStyle}
          data-scrim-top={cover ? 7 : null}
        >
          {hero_settings.particle ? (
            <Particle colors={gradient} id={`particle`} />
          ) : null}
          {cover ? null : <canvas id='canvas-basic' />}
          <div className='hero-body'>
            <div className='container'>
              <Fade cascade>
                <div>
                  <h1
                    className={`title intro`}
                    style={title_style}
                  >
                    {title}
                  </h1>
                  <h2
                    className={`subtitle intro`}
                    style={title_style}
                  >
                    {subtitle}
                  </h2>

                  <div>
                    {cta_title && (
                      <AnchorLink
                        className={
                          'button is-primary is-large is-inverted is-outlined'
                        }
                        href={cta_url}
                        offset={84}
                      >
                        {cta_title}
                      </AnchorLink>
                    )}

                    {cta_video_link && (
                      <button
                        aria-label='open modal window playing a video'
                        className={`button is-large ${modal_video_link[3]}`}
                        onClick={this.openModal}
                      >
                        <span>{modal_video_link[2]}</span>
                        <span className='icon'>
                          <FaPlayCircle size='1em' />
                        </span>
                      </button>
                    )}
                  </div>
                </div>
              </Fade>
            </div>
          </div>
        </section>
        {cta_video_link && (
          <ModalVideo
            channel={modal_video_link[0]}
            isModalOpen={this.state.isModalOpen}
            videoId={modal_video_link[1]}
            onClose={() => this.setState({ isModalOpen: false })}
          />
        )}
      </div>
    )
  }
}

export default HeroBanner
