import React from 'react';
import Particles from 'react-particles-js';

export default class Particle extends React.Component {
  constructor (props) {
    super(props)
    // console.log(props);
  }

  render () {
    let default_colors = [
      '#0f3f97',
      '#145ab8',
      '#176bcc',
      '#1a7de1',
      '#1d8bf1',
      '#1a237e',
    ]

    var colors = this.props.colors

    colors ? null : default_colors

    return (
      <Particles
        canvasClassName={`particles`}
        params={{
          particles: {
            number: {
              value: 40,
              density: {
                enable: true,
                value_area: 800,
              },
            },
            color: {
              value: colors,
            },
            shape: {
              type: 'circle',
              stroke: {
                width: 0,
                color: '#1d8bf1',
              },
              polygon: {
                nb_sides: 10,
              },
              image: {
                src: 'img/github.svg',
                width: 100,
                height: 100,
              },
            },
            opacity: {
              value: 0.9,
              random: false,
              anim: {
                enable: true,
                speed: 1,
                opacity_min: 0.1,
                sync: false,
              },
            },
            size: {
              value: 6,
              random: true,
              anim: {
                enable: false,
                speed: 20,
                size_min: 0.1,
                sync: false,
              },
            },
            line_linked: {
              enable: false,
              distance: 150,
              color: '#fff',
              opacity: 0.4,
              width: 1,
            },
            move: {
              enable: true,
              speed: 4,
              direction: 'none',
              random: true,
              straight: false,
              out_mode: 'none',
              bounce: false,
              attract: {
                enable: true,
                rotateX: 600,
                rotateY: 1200,
              },
            },
          },
          interactivity: {
            detect_on: 'canvas',
            events: {
              onhover: {
                enable: true,
                mode: ['repulse', 'bubble'],
              },
              onclick: {
                enable: true,
                mode: 'push',
              },
              resize: true,
            },
            modes: {
              grab: {
                distance: 400,
                line_linked: {
                  opacity: 1,
                },
              },
              bubble: {
                distance: 400,
                size: 8,
                duration: 2,
                opacity: 8,
                speed: 3,
              },
              repulse: {
                distance: 200,
                duration: 0.4,
              },
              push: {
                particles_nb: 4,
              },
              remove: {
                particles_nb: 2,
              },
            },
          },
          retina_detect: true,
        }}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
        }}
        height={`100%`}
      />
    )
  }
}
